import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    Paper,
    capitalize,
    InputAdornment,
    OutlinedInput,
} from "@mui/material";
import AdminLoader from "../../components/AdminLoader";
import { APICALL } from "../../../helper/api/api";
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SubscriptionPlansList = () => {
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const naviagte = useNavigate();

    const fetchSubscriptionPlans = async () => {
        setLoading(true);
        try {
            const res = await APICALL("common/getAllPlans", "Post", {});
            if (res?.status) {
                setData(res?.plans);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSubscriptionPlans();
    }, []);

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const editPlan = (item) => {
        naviagte(`/admin/plan-edit/${item._id}`, { state: item })
    }

    const planWiseSubscriber = (item) => {
        naviagte(`/admin/subscribers-list/${item._id}`, { state: item._id })
    }


    return (
        <>
            <Paper className="table_samepattern">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "16px",
                    }}
                >
                    <h1 className="title-admins-table">{t('Subscription Plans')}</h1>

                </div>

                <TableContainer>
                    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="text-nowrap rtl_start" align="start">{t('S.NO')}</TableCell>
                                <TableCell className="text-nowrap ">{t('Name')}</TableCell>
                                <TableCell className="text-nowrap ">{t('Duration In Months')}</TableCell>
                                <TableCell className="text-nowrap ">{t('Price')}</TableCell>
                                <TableCell className="text-nowrap ">{t('What Included')}</TableCell>
                                <TableCell className="text-nowrap rtl_text text-end">{t('Action')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading
                                ? <AdminLoader />
                                :
                                <>
                                    {data?.map((item, i) => (
                                        <TableRow>
                                            <TableCell className="rtl_start" align="start">{i + 1}</TableCell>
                                            <TableCell>{item?.name}</TableCell>
                                            <TableCell>{item?.durationInMonths}</TableCell>
                                            <TableCell>{item?.price}</TableCell>
                                            <TableCell>{item?.whatIncluded}</TableCell>
                                            <TableCell className="rtl_text text-end">
                                             <div className="subs_btn d-flex gap-2">
                                             <Button className="global_btn p-2 me-2 text-nowrap" onClick={() => planWiseSubscriber(item)}>{t('Subscribers List')}</Button>
                                             <Button className="list_btn p-2 me-2 text-nowrap" onClick={() => editPlan(item)}><i class="fa-solid fa-pen"></i></Button>
                                         
                                             </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    )
}

export default SubscriptionPlansList