import React from "react";
import PropTypes from "prop-types";
import MainCard from "../../components/MainCard";
import { APICALL } from "../../../helper/api/api";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Paper,
  capitalize,
  InputAdornment,
  OutlinedInput,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import "../../../assets/css/admin.css";
import AdminLoader from "../../components/AdminLoader";
import { Dropdown, FormControl, Modal } from "react-bootstrap";
import {
  Delete,
  Details,
  Edit,
  MoreVert,
  SearchOutlined,
} from "@mui/icons-material";
import { useDataContext } from "../../../helper/context/ContextProvider";
import TableMSG from "../../../components/TableMSG";
import { filterByKey, toastifySuccess } from "../../../helper/Utility";
import {
  TABLE_PAGINATION_DROPDOWN,
  TABLE_ROW_PER_PAGE,
} from "../../../helper/Constant";
import { EyeFilled } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../ConfirmationModal";
const Users = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { role } = useParams();
  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const { permisionData } = useDataContext();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROW_PER_PAGE);
  const permisionCheck = filterByKey(
    "userManagement",
    permisionData?.permissions
  );

  useEffect(() => {
    if (role) {
      getListFun();
    }
  }, [role]);

  const getListFun = async () => {
    setLoading(true);
    try {
      const params = { roles: [role] };
      const res = await APICALL("admin/getAllUsers", "post", params);
      setLoading(false);
      if (res?.status) {
        setList(res?.data?.users);
      }
      console.log(res);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, TABLE_ROW_PER_PAGE));
    setPage(0);
  };

  const filteredList = list.filter(
    (user) =>
      user?.phone?.toLowerCase().includes(search?.toLowerCase()) ||
      user?.schoolDetails?.schoolName
        ?.toLowerCase()
        .includes(search?.toLowerCase()) ||
      user?.name?.toLowerCase().includes(search?.toLowerCase()) ||
      user?.transporterDetails?.transporterName
        ?.toLowerCase()
        .includes(search?.toLowerCase())
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

  const viewDetails = (row) => {
    navigate(`/admin/user-details/${row._id}`, { state: row });
  };

  const editDetails = (row) => {
    if (role === "School") {
      navigate(`/admin/school-details-edit/${row._id}`, { state: row });
    } else if (role === "Transport") {
      navigate(`/admin/transport-details-edit/${row._id}`, { state: row });
    }
  };

  const handleSwitchChange = async (id) => {
    setList((prevList) =>
      prevList.map((item) =>
        item._id === id ? { ...item, status: !item.status } : item
      )
    );
    try {
      const res = await APICALL("admin/toggleUserStatus", "post", { id: id });
      if (res?.status) {
        const newStatus = res?.data?.status;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [modalShow, setModalShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const handleDelete = async () => {
    setIsSubmit(true);
    try {
      const res = await APICALL("admin/softDelete", "post", {
        id: selectedData?._id,
        type: role,
      });
      if (res?.status) {
        setIsSubmit(false);
        setModalShow(false);
        getListFun();
        toastifySuccess(res?.message);
      }
    } catch (error) {
      setIsSubmit(false);
    }
  };
  return (
    <>
      <Paper className="table_samepattern">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <h1 className="title-admins-table">{t(role + "s")}</h1>
          <TextField
            variant="outlined"
            placeholder={t("Search") + "..."}
            value={search}
            onChange={handleSearchChange}
            style={{ width: "300px" }}
          />
        </div>
        {loading ? (
          <AdminLoader />
        ) : (
          <>
            <TableContainer component={Paper}>
              {permisionCheck?.read ? (
                <Table
                  sx={{ minWidth: 500 }}
                  aria-label="custom pagination table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="text-start rtl_start" align="start">
                        {t("S.NO")}
                      </TableCell>
                      <TableCell>{t("Name")}</TableCell>
                      <TableCell>{t("Phone")}</TableCell>
                      {role !== "Parent" ? (
                        <>
                          <TableCell>{t("Rating")}</TableCell>
                          <TableCell>{t("Status")}</TableCell>
                        </>
                      ) : (
                        ""
                      )}
                      <TableCell className="rtl_text text-end">
                        {t("Action")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? filteredList.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : filteredList
                      ).map((row, i) => (
                        <TableRow key={row._id}>
                          <TableCell
                            className="rtl_start text-start "
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {i + 1}
                          </TableCell>
                          <TableCell>
                            {role == "School" ? (
                              <>{row?.schoolDetails?.schoolName}</>
                            ) : role == "Transport" ? (
                              <>{row?.transporterDetails?.transporterName}</>
                            ) : (
                              <>{row?.name}</>
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.phone}
                          </TableCell>
                          {role !== "Parent" ? (
                            <>
                              <TableCell>{row.rating}</TableCell>
                              <TableCell>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={row?.status}
                                        onChange={() =>
                                          handleSwitchChange(row?._id)
                                        }
                                        sx={{
                                          "& .MuiSwitch-switchBase.Mui-checked":
                                            {
                                              color: "#492780", // Thumb color when checked
                                            },
                                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                            {
                                              backgroundColor: "#492780", // Track color when checked
                                            },
                                        }}
                                      />
                                    }
                                  />
                                </FormGroup>
                              </TableCell>
                            </>
                          ) : (
                            ""
                          )}
                          <TableCell className="text-end rtl_text">
                            <Dropdown className="dorpdown-curtom">
                              <Dropdown.Toggle as={IconButton} variant="link">
                                <MoreVert />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {role !== "Parent" ? (
                                  <Dropdown.Item className="p-0">
                                    <Button
                                      className="edit_btn"
                                      onClick={() => editDetails(row)}
                                    >
                                      <Edit style={{ marginRight: "8px" }} />
                                      {t("Edit")}
                                    </Button>
                                  </Dropdown.Item>
                                ) : (
                                  ""
                                )}
                                <Dropdown.Item className="p-0">
                                  <Button
                                    className="edit_btn"
                                    onClick={() => viewDetails(row)}
                                  >
                                    <EyeFilled style={{ marginRight: "8px" }} />
                                    {t("Details")}
                                  </Button>
                                </Dropdown.Item>
                                {role === "Parent" && (
                                  <Dropdown.Item className="p-0">
                                    <Button
                                      className="edit_btn"
                                      onClick={() => {
                                        setModalShow(true);
                                        setSelectedData(row);
                                      }}
                                    >
                                      <DeleteIcon
                                        style={{ marginRight: "8px" }}
                                      />
                                      {t("Delete")}
                                    </Button>
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </TableCell>
                        </TableRow>
                      ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={TABLE_PAGINATION_DROPDOWN}
                          count={filteredList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          slotProps={{
                            select: {
                              inputProps: {
                                "aria-label": "rows per page",
                              },
                              native: true,
                            },
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </>
                </Table>
              ) : (
                <TableMSG
                  msg={"You Don't have permision to view this data"}
                  type={true}
                />
              )}
            </TableContainer>
          </>
        )}
      </Paper>

      <ConfirmationModal
        {...{ modalShow, setModalShow, isSubmit }}
        fun={handleDelete}
        closeBtnName={t("Close")}
        actionBtnName={t("Delete")}
        title={t("Confirmation Modal")}
        body={t("Are you sure you want to Delete this") + " ?"}
      />
    </>
  );
};

export default Users;
