import React from "react";
import PropTypes from "prop-types";
import MainCard from "../../components/MainCard";
import { APICALL } from "../../../helper/api/api";
import { useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    Paper,
    capitalize,
    InputAdornment,
    OutlinedInput,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import "../../../assets/css/admin.css";
import AdminLoader from "../../components/AdminLoader";
import { Dropdown, FormControl, Modal } from "react-bootstrap";
import { Delete, Edit, MoreVert, SearchOutlined } from "@mui/icons-material";
import { useDataContext } from './../../../helper/context/ContextProvider';
import TableMSG from "../../../components/TableMSG";
import { filterByKey } from "../../../helper/Utility";
import { TABLE_PAGINATION_DROPDOWN, TABLE_ROW_PER_PAGE } from './../../../helper/Constant';
import RatingModal from "../RatingModal";
import { useTranslation } from "react-i18next";
 
const Rating = () => {
 
    const { t } = useTranslation();
 
    function TablePaginationActions(props) {
 
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
 
        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };
 
        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };
 
        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };
 
        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };
 
        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }
 
    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };
 
 
    const { permisionData } = useDataContext();
 
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROW_PER_PAGE);
    const permisionCheck = filterByKey("userManagement", permisionData?.permissions);
 
    useEffect(() => {
        if (permisionCheck?.read) {
            getListFun();
        }
    }, []);
 
    const getListFun = async () => {
        setLoading(true);
        try {
            const params = { 'roles': ['Transport', 'School'] }
            const res = await APICALL("admin/getAllUsers", "post", params);
            setLoading(false);
            if (res?.status) {
                setList(res?.data?.users);
            }
            console.log(res);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
 
    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };
 
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
 
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, TABLE_ROW_PER_PAGE));
        setPage(0);
    };
 
    const filteredList = list.filter(
        (user) =>
            user?.phone?.toLowerCase().includes(search?.toLowerCase()) ||
            user?.schoolDetails?.schoolName?.toLowerCase().includes(search?.toLowerCase()) ||
            user?.name?.toLowerCase().includes(search?.toLowerCase()) ||
            user?.transporterDetails?.transporterName?.toLowerCase().includes(search?.toLowerCase())
    );
 
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;
    const [modalShow, setModalShow] = useState(false);
    const [objData, setObjData] = useState(null);
 
    const modalOpenFun = (row) => {
        setModalShow(true)
        setObjData(row)
    }
 
    return (
        <>
            <Paper className="table_samepattern">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px",
                    }}
                >
                    <h1 className="title-admins-table">{t('Assign Rating')}</h1>
                    <TextField
                        variant="outlined"
                        placeholder={t('Search') + "..."}
                        value={search}
                        onChange={handleSearchChange}
                        style={{ width: "300px" }}
                    />
                </div>
                {loading ? (
                    <AdminLoader />
                ) : (
                    <>
                        <TableContainer component={Paper}>
 
                            {
                                permisionCheck?.read ?
                                    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="rtl_start" align=" start">{t('S.NO')}</TableCell>
                                                <TableCell>{t('Name')}</TableCell>
                                                <TableCell>{t('Role')}</TableCell>
                                                <TableCell>{t('Phone')}</TableCell>
                                                <TableCell>{t('Rating')}</TableCell>
                                                <TableCell className="rtl_text text-end">{t('Action')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <>
                                            <TableBody>
                                                {(rowsPerPage > 0
                                                    ? filteredList.slice(
                                                        page * rowsPerPage,
                                                        page * rowsPerPage + rowsPerPage
                                                    )
                                                    : filteredList
                                                ).map((row, i) => (
                                                    <TableRow key={row._id}>
                                                        <TableCell className="rtl_start text-start" component="th" scope="row" align="center">
                                                            {i + 1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.role == "School" ?
                                                                <>
                                                                    {row?.schoolDetails?.schoolName}
                                                                </>
                                                                :
                                                                row?.role == "Transport"
                                                                    ?
                                                                    <>
                                                                        {row?.transporterDetails?.transporterName}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {row?.name}
                                                                    </>
                                                            }
 
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.role}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.phone}
                                                        </TableCell>
                                                        <TableCell>{row.rating}</TableCell>
                                                        <TableCell className="text-end rtl_text">
                                                            <Button className="global_btn p-2 btn btn-primary" onClick={() => modalOpenFun(row)}>{t('Rate')}</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                {emptyRows > 0 && (
                                                    <TableRow style={{ height: 53 * emptyRows }}>
                                                        <TableCell colSpan={6} />
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={TABLE_PAGINATION_DROPDOWN}
                                                        count={filteredList.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        slotProps={{
                                                            select: {
                                                                inputProps: {
                                                                    "aria-label": "rows per page",
                                                                },
                                                                native: true,
                                                            },
                                                        }}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActions}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </>
 
                                    </Table>
                                    :
                                    <TableMSG msg={"You Don't have permision to view this data"} type={true} />
                            }
                        </TableContainer>
                    </>
                )}
 
                <RatingModal {...{ modalShow, setModalShow, objData, setObjData, getListFun }} />
            </Paper>
        </>
    )
}
 
export default Rating