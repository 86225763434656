import React from 'react';
import './PrivacyPolicy.css';

const TermsConditions = () => {
    return (
        <div className="container">
            <div className="privacy-policy">
                <h1>Terms & Conditions</h1>

                <h2>1. Eligibility</h2>
                <p>By using our platform, you represent and warrant that:</p>
                <ul>
                    <li>You are at least 18 years old or have parental/guardian consent.</li>
                    <li>You have the authority to enter into this agreement on behalf of yourself or the organization you represent.</li>
                    <li>Your use of the platform complies with all applicable laws and regulations.</li>
                </ul>

                <h2>2. User Responsibilities</h2>
                <h3>2.1. Account Creation and Maintenance</h3>
                <ul>
                    <li>You agree to provide accurate, complete, and up-to-date information when creating an account on our platform.</li>
                    <li>You are responsible for maintaining the confidentiality of your login credentials.</li>
                    <li>You agree to notify us immediately of any unauthorized use of your account.</li>
                </ul>

                <h3>2.2. Prohibited Activities</h3>
                <ul>
                    <li>You agree not to:</li>
                    <li>Use the platform for any unlawful, fraudulent, or harmful purpose.</li>
                    <li>Interfere with or disrupt the operation of the platform.</li>
                    <li>Attempt to gain unauthorized access to any part of the platform or its systems.</li>
                    <li>Share false or misleading information.</li>
                </ul>

                <h2>3. Services Provided</h2>
                <h3>3.1. Schools</h3>
                <p>We provide tools for schools to manage student records, communicate with parents, and handle administrative tasks efficiently.</p>

                <h3>3.2. Transport Companies</h3>
                <p>We offer services to streamline transport logistics, including route planning, vehicle tracking, and scheduling.</p>

                <h3>3.3. Parents</h3>
                <p>Parents can access their child's academic and transport information, as well as communicate with schools and transport providers.</p>
            </div>
        </div>
    );
};

export default TermsConditions;
