import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Paper,
  capitalize,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';
import { APICALL } from '../../../helper/api/api';
import { imgBaseURL, toastifySuccess } from '../../../helper/Utility';
import ConfirmationModal from '../ConfirmationModal';
import AdminLoader from '../../components/AdminLoader';
import BackButton from '../BackButton';
import { useTranslation } from 'react-i18next';

const SubscribersList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const id = location.state;

  const getSubscribersList = async () => {
    setLoading(true);
    try {
      const res = await APICALL("admin/planWiseSubscriber", "Post", { planId: id });
      if (res?.status) {
        setSubscribers(res?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const image_base_url = imgBaseURL();

  useEffect(() => {
    getSubscribersList();
  }, [id]);

  return (
    <>
      <Paper className="table_samepattern">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px",
          }}
        >
          <h1 className="title-admins-table">{t('Subscribers List')}</h1>

        </div>

        <TableContainer>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell className="rtl_start" align="start">{t('S.NO')}</TableCell>
                <TableCell>{t('Logo')}</TableCell>
                <TableCell>{t('Name')}</TableCell>
                <TableCell>{t('Phone')}</TableCell>
                <TableCell>{t('Role')}</TableCell>
                <TableCell>{t('Plat Form')}</TableCell>
                <TableCell>{t('Status')}</TableCell>
                <TableCell>{t('Duration (Months)')}</TableCell>
                <TableCell>{t('Date')}</TableCell>
                <TableCell>{t('Next Renew Date')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? <AdminLoader />
                :
                <>
                  {subscribers?.map((item, i) => (
                    <TableRow>
                      <TableCell className="rtl_start" align="start">{i + 1}</TableCell>
                      <TableCell>
                        <img src={item?.userRole === "School" ? image_base_url + item?.schoolDetails?.logo : image_base_url + item?.transporterDetails?.logo} style={{ width: "100px", height: '100px', objectFit: 'cover' }} />
                      </TableCell>
                      <TableCell>{item?.userRole === "School" ? item?.schoolDetails?.schoolName : item?.transporterDetails?.transporterName}</TableCell>
                      <TableCell>{item?.userDetails?.phone}</TableCell>
                      <TableCell>{item?.userRole}</TableCell>
                      <TableCell>{item?.platform}</TableCell>
                      <TableCell>{item?.status}</TableCell>
                      <TableCell>
                        {item?.months} Month
                      </TableCell>
                      <TableCell>
                        {item?.createdAt ?
                          new Date(item.createdAt).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })
                          : null}
                      </TableCell>
                      <TableCell>
                        {item?.createdAt && item?.months ?
                          new Date(new Date(item.createdAt).setMonth(new Date(item.createdAt).getMonth() + item.months))
                            .toLocaleDateString('en-GB', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            })
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default SubscribersList;
