import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Row,
  Button,
  CardHeader,
  CardFooter,
  Card,
  CardBody,
  Col,
  Form,
  Table,
} from "react-bootstrap";
import {
  imgBaseURL,
  toastifyError,
  toastifySuccess,
} from "../../../helper/Utility";
import { APICALL } from "../../../helper/api/api";
import AdminLoader from "../../components/AdminLoader";
import BackButton from "../BackButton";
import ConfirmationModal from "../ConfirmationModal";
import fixAvatar from "../../assets/images/users/avatar.jpg";
import { useTranslation } from "react-i18next";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const UserDetail = () => {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const fetchDetails = async () => {
    try {
      setLoading(true);
      const res = await APICALL("transport/getTransportWithVehicles", "post", {
        id: data?._id,
      });
      if (res?.status) {
        setLoading(false);
        setUser((prevUser) => ({
          ...prevUser,
          ...res?.data,
        }));
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    if (data?.role === "Transport") {
      setUser(data);
      fetchDetails();
    } else {
      setUser(data);
    }
  }, [data]);

  const editDetails = (user) => {
    if (data?.role === "School") {
      navigate(`/admin/school-details-edit/${user._id}`, { state: user });
    } else if (data?.role === "Transport") {
      navigate(`/admin/transport-details-edit/${user._id}`, { state: user });
    }
  };

  const editVehicle = (vehicle) => {
    navigate(`/admin/vehicle-edit/${vehicle._id}`, { state: vehicle });
  };

  const { t } = useTranslation();

  const [key, setKey] = useState("quickInfo");

  return (
    <>
      <Card className="card-cusotom ">
        <CardHeader>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "10px" }}
          >
            <div className="d-flex align-items-center" style={{ gap: "10px" }}>
              <BackButton />
              <h2 className="title-admins-table m-0">{t(data?.role)}</h2>
            </div>
          </div>
        </CardHeader>
        {loading ? (
          <AdminLoader />
        ) : (
          <CardBody>
            <div className="cutoms-login-artist">
              {data?.role === "Transport" ? (
                <>
                  {user?.transporterDetails?.logo && (
                    <Row className="mb-md-3 mb-2">
                      <Col md={12} className="mb-2">
                        <h2 className="mt-0">{t("Logo")}</h2>
                      </Col>
                      <Col lg={2}>
                        <div className="view-admin-detials">
                          <img
                            src={imgBaseURL() + user?.transporterDetails?.logo}
                            alt="Logo"
                            className="image_ui"
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                  <div className="school_page mt-3 mb-3">
                    <Tabs
                      defaultActiveKey="transportPersonalInfo"
                      id="user-info-tabs"
                      className="mb-3"
                    >
                      {/* Personal Info Tab */}
                      <Tab
                        eventKey="transportPersonalInfo"
                        title={t("Personal Info")}
                      >
                        <Row className="mb-md-3 mb-2">
                          <Col md={12} className="mb-2">
                            <h2 className="mt-0">{t("Personal Info")}</h2>
                          </Col>
                          <Col lg={3} className="mb-4">
                            <div className="view-admin-detials">
                              <h5>{t("Name")}</h5>
                              <p>{user?.transporterDetails?.transporterName}</p>
                            </div>
                          </Col>
                          <Col lg={3} className="mb-4">
                            <div className="view-admin-detials">
                              <h5>{t("Rating")}</h5>
                              <p>{user?.rating}</p>
                            </div>
                          </Col>
                          <Col lg={3} className="mb-4">
                            <div className="view-admin-detials">
                              <h5>{t("About Us")}</h5>
                              <p>{user?.transporterDetails?.aboutUs}</p>
                            </div>
                          </Col>
                          <Col lg={3} className="mb-4">
                            <div className="view-admin-detials">
                              <h5>{t("Phone")}</h5>
                              <p>{user?.phone}</p>
                            </div>
                          </Col>
                        </Row>
                      </Tab>

                      {/* Quick Info Tab */}
                      <Tab
                        eventKey="transportQuickInfo"
                        title={t("Quick Info")}
                      >
                        <Row className="mb-md-3 mb-2">
                          <Col md={12} className="mb-2">
                            <h2 className="mt-0">{t("Quick Info")}</h2>
                          </Col>
                          <Col lg={3} className="mb-4">
                            <div className="view-admin-detials">
                              <h5>{t("Contact No")}</h5>
                              <p>
                                {
                                  user?.transporterDetails?.quick_info
                                    ?.contact_no
                                }
                              </p>
                            </div>
                          </Col>
                          <Col lg={3} className="mb-4">
                            <div className="view-admin-detials">
                              <h5>{t("Business Hours")}</h5>
                              <p>
                                {
                                  user?.transporterDetails?.quick_info
                                    ?.business_hours
                                }
                              </p>
                            </div>
                          </Col>
                          <Col lg={3} className="mb-4">
                            <div className="view-admin-detials">
                              <h5>{t("Since")}</h5>
                              <p>
                                {user?.transporterDetails?.quick_info?.since}
                              </p>
                            </div>
                          </Col>
                          <Col lg={3} className="mb-4">
                            <div className="view-admin-detials">
                              <h5>{t("Website")}</h5>
                              <Link
                                target="_blank"
                                to={
                                  user?.transporterDetails?.quick_info?.website
                                }
                              >
                                {user?.transporterDetails?.quick_info?.website}
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Tab>

                      {/* Social Media Tab */}
                      <Tab
                        eventKey="transportSocialMedia"
                        title={t("Social Media")}
                      >
                        <Row className="mb-md-3 mb-2">
                          <Col md={12} className="mb-2">
                            <h2 className="mt-0">{t("Social Media")}</h2>
                          </Col>
                          <Col lg={3} className="mb-4">
                            <div className="view-admin-detials">
                              <h5>{t("Facebook")}</h5>
                              <Link
                                target="_blank"
                                to={
                                  user?.transporterDetails?.social_media
                                    ?.facebook
                                }
                              >
                                {
                                  user?.transporterDetails?.social_media
                                    ?.facebook
                                }
                              </Link>
                            </div>
                          </Col>
                          <Col lg={3} className="mb-4">
                            <div className="view-admin-detials">
                              <h5>{t("Instagram")}</h5>
                              <Link
                                target="_blank"
                                to={
                                  user?.transporterDetails?.social_media
                                    ?.instagram
                                }
                              >
                                {
                                  user?.transporterDetails?.social_media
                                    ?.instagram
                                }
                              </Link>
                            </div>
                          </Col>
                          <Col lg={3} className="mb-4">
                            <div className="view-admin-detials">
                              <h5>{t("Twitter")}</h5>
                              <Link
                                target="_blank"
                                to={
                                  user?.transporterDetails?.social_media
                                    ?.twitter
                                }
                              >
                                {
                                  user?.transporterDetails?.social_media
                                    ?.twitter
                                }
                              </Link>
                            </div>
                          </Col>
                          <Col lg={3} className="mb-4">
                            <div className="view-admin-detials">
                              <h5>{t("YouTube")}</h5>
                              <Link
                                target="_blank"
                                to={
                                  user?.transporterDetails?.social_media
                                    ?.youtube
                                }
                              >
                                {
                                  user?.transporterDetails?.social_media
                                    ?.youtube
                                }
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </div>

                  <Row className="mb-md-3 mb-2">
                    <Col md={12} className="mb-2">
                      <h2 className="mt-3 mb-0">{t("Vehicles")}</h2>
                    </Col>
                    <Col lg={12} sm={12}>
                    <div className="table-responsive">
  <Table bordered>
    <thead>
      <tr>
        <th className="text-nowrap">{t("Vehicle Title")}</th>
        <th className="text-nowrap">{t("Route Information")}</th>
        <th className="text-nowrap">{t("Driver Information")}</th>
        <th className="text-nowrap">{t("Maintenance Records")}</th>
        <th className="text-nowrap">{t("Insurance Details")}</th>
        <th className="text-nowrap">{t("Emergency Contact")}</th>
        <th className="text-nowrap">{t("Action")}</th>
      </tr>
    </thead>
    <tbody>
      {user?.vehicles?.map((vehicle) => (
        <tr key={vehicle._id}>
          <td>{vehicle.vehicleTitle}</td>
          <td>{vehicle.routeInformation}</td>
          <td>{vehicle.driverInformation}</td>
          <td>{vehicle.maintenanceRecords}</td>
          <td>{vehicle.insuranceDetails}</td>
          <td>{vehicle.emergencyContact}</td>
          <td>
            <button
              className="artist-btn"
              onClick={() => editVehicle(vehicle)}
            >
              {t("Edit")}
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
</div>

                    </Col>
                  </Row>
                </>
              ) : data?.role === "School" ? (
                <>
                  <Row className="my_schol_box">
                    <Col md={12}>
                      {user?.schoolDetails?.logo && (
                        <Row className="mb-md-3 mb-2">
                          <div className="view-admin-detials">
                            <img
                              src={imgBaseURL() + user?.schoolDetails?.logo}
                              alt="School Logo"
                              className="image_ui"
                            />
                          </div>
                        </Row>
                      )}

                      <Row className="">
                        <Col md={12}>
                          <ul className="icon_social">
                            <li>
                              <Link
                                target="_blank"
                                to={user?.schoolDetails?.social_media?.facebook}
                              >
                                <i class="fa-brands fa-facebook-f"></i>
                              </Link>
                            </li>

                            <li>
                              <Link
                                target="_blank"
                                to={
                                  user?.schoolDetails?.social_media?.instagram
                                }
                              >
                                <i class="fa-brands fa-instagram"></i>
                              </Link>
                            </li>

                            <li>
                              <Link
                                target="_blank"
                                to={user?.schoolDetails?.social_media?.twitter}
                              >
                                <i class="fa-brands fa-square-x-twitter"></i>
                              </Link>
                            </li>

                            <li>
                              {" "}
                              <Link
                                target="_blank"
                                to={user?.schoolDetails?.social_media?.youtube}
                              >
                                <i class="fa-brands fa-youtube"></i>
                              </Link>
                            </li>
                          </ul>
                        </Col>
                        <Col lg={12} className="mb-3">
                          <div className="view-admin-detials d-flex gap-2 align-items-center mt-2">
                            <h2 className="mt-0 mb-0">
                              {user?.schoolDetails?.schoolName}
                            </h2>
                            <p className="rating_box m-0">
                              <i class="fa-solid fa-star"></i> {user?.rating}
                            </p>
                          </div>
                          <div className="view-admin-detials mt-3">
                            <p>{user?.schoolDetails?.aboutUs}</p>
                          </div>
                        </Col>
                      </Row>

                      <div className="phone_btn">
                        <a className="" href={`tel:${user?.phone}`}>
                          <i class="fa-solid fa-phone"></i> {user?.phone}
                        </a>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="school_page">
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={key}
                          onSelect={(k) => setKey(k)}
                          className="mb-3"
                        >
                          <Tab eventKey="quickInfo" title="Quick Info">
                            <Row className="mb-md-3 mb-2">
                              <Col md={12} className="mb-2">
                                <h2 className="m-0">{t("Quick Info")}</h2>
                              </Col>
                              <Col lg={6} className="mb-3">
                                <div className="view-admin-detials">
                                  <h5>{t("Contact No")}</h5>
                                  <p>
                                    {
                                      user?.schoolDetails?.quick_info
                                        ?.contact_no
                                    }
                                  </p>
                                </div>
                              </Col>
                              <Col lg={6} className="mb-3">
                                <div className="view-admin-detials">
                                  <h5>{t("Business Hours")}</h5>
                                  <p>
                                    {
                                      user?.schoolDetails?.quick_info
                                        ?.business_hours
                                    }
                                  </p>
                                </div>
                              </Col>
                              <Col lg={6} className="mb-3">
                                <div className="view-admin-detials">
                                  <h5>{t("Since")}</h5>
                                  <p>
                                    {user?.schoolDetails?.quick_info?.since}
                                  </p>
                                </div>
                              </Col>
                              <Col lg={6} className="mb-3">
                                <div className="view-admin-detials">
                                  <h5>{t("Website")}</h5>
                                  <Link
                                    target="_blank"
                                    to={
                                      user?.schoolDetails?.quick_info?.website
                                    }
                                  >
                                    {user?.schoolDetails?.quick_info?.website}
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </Tab>

                          <Tab eventKey="home" title="Additional Details">
                            <Row className="mb-md-3 mb-2">
                              <Col md={12} className="mb-2">
                                <h2 className="mt-0">
                                  {t("Additional Details")}
                                </h2>
                              </Col>
                              <Col lg={12}>
                                <div className="view-admin-detials d-md-flex gap-3 mb-3">
                                  <h5>{t("Amenities")} :</h5>
                                  <div className="d-flex gap-3">
                                    {Array.isArray(
                                      user?.schoolDetails?.amenities
                                    ) ? (
                                      user.schoolDetails.amenities.map(
                                        (amenity, index) => (
                                          <p key={index}>{amenity} , </p>
                                        )
                                      )
                                    ) : (
                                      <p>{user?.schoolDetails?.amenities}</p>
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="view-admin-detials d-md-flex gap-3 mb-3">
                                  <h5 className="text-nowrap">
                                    {t("Accreditation Certifications")} :
                                  </h5>
                                  <p>
                                    {
                                      user?.schoolDetails
                                        ?.accreditation_certifications
                                    }
                                  </p>
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="view-admin-detials d-md-flex gap-3 mb-3">
                                  <h5>{t("Program Offered")} :</h5>
                                  <div className="d-flex gap-3">
                                    {Array.isArray(
                                      user?.schoolDetails?.programs_offered
                                    ) ? (
                                      user.schoolDetails.programs_offered.map(
                                        (programsOffered, index) => (
                                          <p key={index}>
                                            {programsOffered} ,{" "}
                                          </p>
                                        )
                                      )
                                    ) : (
                                      <p>
                                        {user?.schoolDetails?.programs_offered}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="view-admin-detials d-flex gap-3">
                                  <h5>{t("Admission Process")} :</h5>
                                  <p>
                                    {user?.schoolDetails?.admission_process}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Tab>

                          <Tab
                            eventKey="profile"
                            title="Fee Structure & Faculty"
                          >
                            <Row className="mb-md-3 mb-2">
                              <Col md={12} className="mb-2">
                                <h2 className="mt-0">
                                  {t("Fee Structure & Faculty")}
                                </h2>
                              </Col>
                              <Col lg={12}>
                                <div className="view-admin-detials d-md-flex gap-3 mb-3">
                                  <h5>{t("Fee Structure")} :</h5>
                                  <p>{user?.schoolDetails?.fee_structure}</p>
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="view-admin-detials d-md-flex gap-3 mb-3">
                                  <h5>{t("Faculty Information")} :</h5>
                                  <p>
                                    {user?.schoolDetails?.faculty_information}
                                  </p>
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="view-admin-detials d-md-flex gap-3 mb-3">
                                  <h5>{t("Safety Features")} :</h5>
                                  <div className="d-flex gap-3">
                                    {Array.isArray(
                                      user?.schoolDetails?.safety_features
                                    ) ? (
                                      user.schoolDetails.safety_features.map(
                                        (safetyFeatures, index) => (
                                          <p key={index}>{safetyFeatures} , </p>
                                        )
                                      )
                                    ) : (
                                      <p>
                                        {user?.schoolDetails?.safety_features}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="view-admin-detials d-md-flex gap-3 mb-3">
                                  <h5>{t("Curriculum")} :</h5>
                                  <div className="d-flex gap-3">
                                    {Array.isArray(
                                      user?.schoolDetails?.curriculum
                                    ) ? (
                                      user.schoolDetails.curriculum.map(
                                        (Curriculum, index) => (
                                          <p key={index}>{t(Curriculum)}</p>
                                        )
                                      )
                                    ) : (
                                      <p>{user?.schoolDetails?.curriculum}</p>
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="view-admin-detials d-flex gap-1">
                                  <h5 className="flex-nowrap text-nowrap">
                                    {t("Extracurricular Activities")} :
                                  </h5>
                                  <div className="d-flex gap-2 flex-wrap">
                                    {Array.isArray(
                                      user?.schoolDetails
                                        ?.extracurricular_activities
                                    ) ? (
                                      user.schoolDetails.extracurricular_activities.map(
                                        (extracurricularActivities, index) => (
                                          <p key={index}>
                                            {t(extracurricularActivities)} ,
                                          </p>
                                        )
                                      )
                                    ) : (
                                      <p>
                                        {
                                          user?.schoolDetails
                                            ?.extracurricular_activities
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tab>

                          <Tab eventKey="contact" title="Additional Info">
                            <Row className="mb-md-3 mb-2">
                              <Col md={12} className="mb-2">
                                <h2 className="mt-0">{t("Additional Info")}</h2>
                              </Col>
                              <Col lg={3}>
                                <div className="view-admin-detials">
                                  <h5>{t("School Calendar")}</h5>
                                  <p>{user?.schoolDetails?.school_calender}</p>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="view-admin-detials">
                                  <h5>{t("Student-Teacher Ratio")}</h5>
                                  <p>
                                    {user?.schoolDetails?.student_teacher_ratio}
                                  </p>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="view-admin-detials">
                                  <h5>{t("Digital Infrastructure")}</h5>
                                  <p>
                                    {
                                      user?.schoolDetails
                                        ?.digital_infrastructure
                                    }
                                  </p>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="view-admin-detials">
                                  <h5>{t("Nearby Landmarks")}</h5>
                                  <p>{user?.schoolDetails?.nearby_landmarks}</p>
                                </div>
                              </Col>
                            </Row>
                          </Tab>

                          <Tab eventKey="another" title="Child and Budget Info">
                            <Row className="mb-md-3 mb-2">
                              <Col md={12} className="mb-2">
                                <h2 className="mt-0">
                                  {t("Child and Budget Info")}
                                </h2>
                              </Col>
                              <Col lg={3}>
                                <div className="view-admin-detials">
                                  <h5>{t("Childs Grade")}</h5>
                                  <p>
                                    {t(
                                      user?.schoolDetails?.childs_grade_select
                                    )}
                                  </p>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="view-admin-detials">
                                  <h5>{t("Budget")}</h5>
                                  <p>{t(user?.schoolDetails?.budget)}</p>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="view-admin-detials">
                                  <h5>{t("Special Programs")}</h5>
                                  <p>
                                    {t(user?.schoolDetails?.special_programs)}
                                  </p>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="view-admin-detials">
                                  <h5>{t("School Size")}</h5>
                                  <p>{t(user?.schoolDetails?.school_size)}</p>
                                </div>
                              </Col>
                            </Row>
                          </Tab>

                          <Tab
                            eventKey="languages"
                            title="Languages and Support"
                          >
                            <Row className="mb-md-3 mb-2">
                              <Col md={12} className="mb-2">
                                <h2 className="mt-0">
                                  {t("Languages and Support")}
                                </h2>
                              </Col>
                              <Col lg={12}>
                                <div className="view-admin-detials d-md-flex gap-3 mb-3">
                                  <h5>{t("Languages Offered")} :</h5>
                                  <div className="d-flex gap-2">
                                    {Array.isArray(
                                      user?.schoolDetails?.languages_offered
                                    ) ? (
                                      user.schoolDetails.languages_offered.map(
                                        (languagesOffered, index) => (
                                          <p key={index}>
                                            {t(languagesOffered)} ,
                                          </p>
                                        )
                                      )
                                    ) : (
                                      <p>
                                        {user?.schoolDetails?.languages_offered}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="view-admin-detials d-md-flex gap-3 mb-3">
                                  <h5>{t("Special Needs Support")} :</h5>
                                  <p>
                                    {t(
                                      user?.schoolDetails?.special_needs_support
                                    )}
                                  </p>
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="view-admin-detials d-md-flex gap-3 mb-3">
                                  <h5>{t("Reputation & Accreditation")} :</h5>
                                  <p>
                                    {t(
                                      user?.schoolDetails
                                        ?.reputation_accreditation
                                    )}
                                  </p>
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="view-admin-detials d-md-flex gap-3">
                                  <h5>{t("Safety Environment")} :</h5>
                                  <div className="d-flex gap-3">
                                    {Array.isArray(
                                      user?.schoolDetails?.safety_environment
                                    ) ? (
                                      user.schoolDetails.safety_environment.map(
                                        (safetyEnvironment, index) => (
                                          <p key={index}>
                                            {t(safetyEnvironment)}
                                          </p>
                                        )
                                      )
                                    ) : (
                                      <p>
                                        {
                                          user?.schoolDetails
                                            ?.safety_environment
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tab>
                        </Tabs>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row className="mb-md-3 mb-2">
                    <Col lg={2}>
                      <div className="view-admin-detials">
                        <img
                          src={
                            user?.avatar
                              ? imgBaseURL() + user?.avatar
                              : fixAvatar
                          }
                          alt="Avatar"
                          className="image_ui"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-md-3 mb-2">
                    <Col md={12} className="mb-2">
                      <h2>{t("Personal Info")}</h2>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>{t("Name")}</h5>
                        <p>{user?.name}</p>
                      </div>
                    </Col>

                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>{t("Email")}</h5>
                        <p>{user?.email}</p>
                      </div>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>{t("Phone")}</h5>
                        <p>{user?.phone}</p>
                      </div>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>{t("Childs Grade")}</h5>
                        <p>{user?.childGrade}</p>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </CardBody>
        )}
        {/* <CardFooter>
          {data?.role !== "Parent" ?
            <Button className="artist-btn" onClick={() => editDetails(user)}>Edit</Button>
            : ''}
        </CardFooter> */}
      </Card>
    </>
  );
};

export default UserDetail;
