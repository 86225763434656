import React from 'react';
import './PrivacyPolicy.css';

const Support = () => {
    return (
        <div className="container">
            <div className="privacy-policy">
                <h1>Support</h1>

                <p>Welcome to the SJL Support Center. We are here to assist you with any questions or issues you may have regarding our services. Please find below the various ways you can reach out to us:</p>

                <h2>Contact Information:</h2>
                <ul>
                    <li>
                        <strong>Email:</strong> For general inquiries or support requests, please email us at {" "}
                        <a href="mailto:sjlowner@gmail.com">sjlowner@gmail.com</a>.
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Support;
