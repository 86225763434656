import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Form, Row, Col, Button, CardFooter } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { APICALL } from '../../../helper/api/api';
import { toastifySuccess } from '../../../helper/Utility';
import AdminLoader from '../../components/AdminLoader';
import BackButton from '../BackButton';
import { useTranslation } from 'react-i18next';

const TransportDetailsEdit = () => {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);


  const [formData, setFormData] = useState({
    _id: data._id,
    transporterName: '',
    aboutUs: '',
    contact_no: '',
    business_hours: '',
    since: '',
    website: '',
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: '',
    logo: '',
});

const handleChange = (e) => {
  const { name, value, files } = e.target;

  if (name === "logo") {
    setFormData((prevData) => ({
      ...prevData,
      logo: files[0],
    }));
  } else {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }
};

useEffect(() => {
  const fetchData = () => {
    const initialData = {
      _id: data._id,
      transporterName: data?.transporterDetails?.transporterName || '',
      aboutUs: data?.transporterDetails?.aboutUs || '',
      contact_no: data?.transporterDetails?.quick_info?.contact_no || '',
      business_hours: data?.transporterDetails?.quick_info?.business_hours || '',
      since: data?.transporterDetails?.quick_info?.since || '',
      website: data?.transporterDetails?.quick_info?.website || '',
      facebook: data?.transporterDetails?.social_media?.facebook || '',
      instagram: data?.transporterDetails?.social_media?.instagram || '',
      twitter: data?.transporterDetails?.social_media?.twitter || '',
      youtube: data?.transporterDetails?.social_media?.youtube || '',
      logo: null,
    };
    setFormData(initialData);
  };

  fetchData();
}, [data]);

const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);
  try {
    const perms = new FormData();
    
    perms.append('_id', formData._id);
    
    if (formData.logo) {
      perms.append('logo', formData.logo);
    }

    perms.append('transporterName', formData.transporterName);
    perms.append('aboutUs', formData.aboutUs);
    
    perms.append("quick_info[contact_no]", formData.contact_no);
    perms.append("quick_info[business_hours]", formData.business_hours);
    perms.append("quick_info[since]", formData.since);
    perms.append("quick_info[website]", formData.website);
    
    perms.append("social_media[facebook]", formData.facebook);
    perms.append("social_media[instagram]", formData.instagram);
    perms.append("social_media[twitter]", formData.twitter);
    perms.append("social_media[youtube]", formData.youtube);

    const res = await APICALL("transport/updateTransporterDetails", "post", perms);
    
    setLoading(false);
    if (res?.status) {
      navigate(-1);
      toastifySuccess(res?.message);
    }
    console.log(res);
    
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

const { t }  =useTranslation();

  
  

  return (
    <>
      <Card className="card-cusotom card">
      <CardHeader>
          <div className="d-flex justify-content-between align-items-center" style={{ gap: '10px' }}>
            <div className="d-flex align-items-center" style={{ gap: '10px' }}>
              <BackButton />
              <h2 className="title-admins-table m-0">{t('Edit Details')}</h2>
            </div>
          </div>
        </CardHeader>
        {loading ? (
                    <AdminLoader />
                ) : (
                  <Form onSubmit={handleSubmit}>
        <CardBody>
        <div className='cutoms-login-artist'>
        <Row>

        <h5 class="mb-4"><b>{t('About Transporter')}</b></h5>
        <Col md={3} className='mb-4'>
          <Form.Group className="mb-3" controlId="transporterName">
            <Form.Label>{t('Transporter Name')}</Form.Label>
            <Form.Control 
              type="text" 
              name='transporterName' 
              value={formData.transporterName} 
              onChange={handleChange} 
              placeholder={t('Transporter Name')} 
            />
          </Form.Group>
        </Col>
    
        <Col md={3} className='mb-4'>
          <Form.Group className="mb-3" controlId="contact_no">
            <Form.Label>{t('Contact No')}</Form.Label>
            <Form.Control 
              type="text" 
              name='contact_no' 
              value={formData.contact_no} 
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleChange(e);
                }
              }}
              maxLength={12}
              placeholder={t('Contact No')}
            />
          </Form.Group>
        </Col>
        <Col md={3} className='mb-4'>
          <Form.Group className="mb-3" controlId="business_hours">
            <Form.Label>{t('Business Hours')}</Form.Label>
            <span style={{fontSize: "12px"}}> ({t("eg:- 9AM to 6PM")})</span>

            <Form.Control 
              type="text" 
              name='business_hours' 
              value={formData.business_hours} 
              onChange={handleChange} 
              placeholder={t('Business Hours')}
            />
          </Form.Group>
        </Col>
        <Col md={3} className='mb-4'>
          <Form.Group className="mb-3" controlId="since">
            <Form.Label>{t('Since')}</Form.Label>
            <Form.Control 
              type="text" 
              name='since' 
              value={formData.since} 
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleChange(e);
                }
              }}
              maxLength={4}
              placeholder={t('Since')}
            />
          </Form.Group>
        </Col>
     <div className='col-md-12'>
    
      <Row>
     <Col md={12} className='mb-4'>
          <Form.Group className="mb-3" controlId="aboutUs">
            <Form.Label>{t('About Us')}</Form.Label>
            <Form.Control 
              as="textarea" 
              rows={3} 
              name='aboutUs'
              value={formData.aboutUs}
              onChange={handleChange}
              placeholder={t('About Us')}
            />
          </Form.Group>
        </Col>
        <h5 class="mb-4"><b>{t('Social Links')}</b></h5>
        <Col md={3} className='mb-4'>
          <Form.Group className="mb-3" controlId="website">
            <Form.Label>{t('Website')}</Form.Label>
            <Form.Control 
              type="text" 
              name='website' 
              value={formData.website} 
              onChange={handleChange} 
              placeholder={t('Website')}
            />
          </Form.Group>
        </Col>
        <Col md={3} className='mb-4'>
          <Form.Group className="mb-3" controlId="facebook">
            <Form.Label>{t('Facebook')}</Form.Label>
            <Form.Control 
              type="text" 
              name='facebook' 
              value={formData.facebook} 
              onChange={handleChange} 
              placeholder={t('Facebook')}
            />
          </Form.Group>
        </Col>
        <Col md={3} className='mb-4'>
          <Form.Group className="mb-3" controlId="instagram">
            <Form.Label>{t('Instagram')}</Form.Label>
            <Form.Control 
              type="text" 
              name='instagram' 
              value={formData.instagram} 
              onChange={handleChange} 
              placeholder={t('Instagram')}
            />
          </Form.Group>
        </Col>
        <Col md={3} className='mb-4'>
          <Form.Group className="mb-3" controlId="twitter">
            <Form.Label>{t('Twitter')}</Form.Label>
            <Form.Control 
              type="text" 
              name='twitter' 
              value={formData.twitter} 
              onChange={handleChange} 
              placeholder={t('Twitter')}
            />
          </Form.Group>
        </Col>
        <Col md={3} className='mb-4'>
          <Form.Group className="mb-3" controlId="youtube">
            <Form.Label>{t('YouTube')}</Form.Label>
            <Form.Control 
              type="text" 
              name='youtube' 
              value={formData.youtube} 
              onChange={handleChange} 
              placeholder={t('YouTube')}
            />
          </Form.Group>
        </Col>
        <Col md={3} className='mb-4'>
          <Form.Group className="mb-3" controlId="logo">
            <Form.Label>{t('Logo')}</Form.Label>
            <input
              type="file" 
              name='logo' 
              className='form-control'
              onChange={handleChange}
            />
          </Form.Group>
        </Col>

        </Row>
     </div>
      </Row>
      </div>
        </CardBody>
        <CardFooter>
        <Button type="submit" className="artist-btn btn btn-primary">
          {t('Update')}
        </Button>
        </CardFooter>
        </Form> 
                )}
      </Card>
    </>
  );
}

export default TransportDetailsEdit;
